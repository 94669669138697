import {TFuncKey, Namespace, KeyPrefix} from 'i18next';
import {DefaultNamespace} from 'react-i18next/TransWithoutContext';
import {Trans, TransProps} from './Trans';
import {twMerge} from 'tailwind-merge';

export function Paragraph<
  K extends TFuncKey<N, TKPrefix> extends infer A ? A : never,
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
  E = React.HTMLProps<HTMLDivElement>
>(
  props: TransProps<K, N, TKPrefix, E> & {
    className?: string;
  }
) {
  return (
    <p className={twMerge('text-gray-500 font-medium', props.className)}>
      <Trans {...props} />
    </p>
  );
}
